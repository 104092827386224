<template>
  <div>
    <vx-card :title="$t('authorization.authorization')" class=" cd-auth-list">
      <!-- 新增授權名單按鈕 -->
      <div class="text-center" v-if="doctorType!=1">{{ $t('authorization.prompt') }}</div>
      <template slot="actions">
        <vs-button v-if="doctorType==1" @click="showAddAuthorization" class="cd-button-queue-member px-3">
          <feather-icon style="vertical-align: middle;" icon="PlusIcon"/>&nbsp;{{$t('authorization.addAuth')}}
        </vs-button>
      </template>
      <vs-divider />
      <vs-row v-show="doctorType==1">
        <vs-col v-for="(item, index) in getmyMedicalNursebind" :key="index">
          <div class="flex justify-between">
            <span>{{item.nurse_full_name}}</span>
            <div class="flex">
              <vs-checkbox v-model="statusList.shiftStatusList" :vs-value="item.nurse_did" @click="updateAuthor(item.nurse_did,'status')">{{$t('authorization.manageShift')}}</vs-checkbox>
              <vs-checkbox v-model="statusList.queueStatusList" :vs-value="item.nurse_did" @click="updateAuthor(item.nurse_did,'status2')">{{$t('authorization.contactPatient')}}</vs-checkbox>
              <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hover:text-primary" @click="showDeleteWarning(delTargetDid = item.nurse_did)"></feather-icon>
            </div>
          </div>
          <vs-divider />
        </vs-col>
      </vs-row>
    </vx-card>
    <!--授權名單視窗-->
    <vs-popup
      :active.sync="addAuthorization"
      :title="$t('authorization.authorization')">
        <vs-input :placeholder="$t('authorization.search')" v-model="searchQuery" class="cd-form-group w-full" />
        <div class="px-2 py-4 flex justify-between items-center cursor-pointer hover:bg-primary hover:text-white" style="line-height: 0;" v-for="(item, index) in getallMedicalNurse" :key="index" @click="addAuth(item.did)">
          <p>{{item.name}}</p>
          <div class="flex items-center">
            <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
          </div>
        </div>
    </vs-popup>
  </div>
</template>
<script>
import { fetchAuthList, updateAuthNurse, updateAuth, delAuthNurse } from '@/api/user'
import vSelect from 'vue-select'
import FeatherIcon from '../components/FeatherIcon.vue'
export default {
  components: {
    vSelect,
    FeatherIcon,
  },
  async created() {
    var _self = this
    await _self.$store.dispatch('fetchProfile')
    _self.fetchAuthList()
    _self.fetchNurseList()
  },
  watch:{
    searchQuery(n){
      if(n!=""){
        var temp = _.filter(this.myMedicalAllNurse, function(o) { return o.name.includes(n) })
        this.tempMyMedicalAllNurse = _.cloneDeep(temp)
      }else{
        this.tempMyMedicalAllNurse = _.cloneDeep(this.myMedicalAllNurse)
      }
    }
  },
  computed: {
    // 醫事人員類型
    doctorType() {
      return this.$store.getters.doctortype
    },
    // 綁定的護理人員
    getmyMedicalNursebind:{
      get(){
        return this.$store.getters.chosenMedicalNurse
      },
      set(){
        return this.$store.getters.chosenMedicalNurse
      }
    },
    // 所有同屬機構醫事人員
    getallMedicalNurse(){
      var _self = this
      if(_self.tempMyMedicalAllNurse.length!=0){
        _self.getmyMedicalNursebind.forEach(function(v){
          _.remove(_self.tempMyMedicalAllNurse, function(n) {
            return n.did == v.nurse_did;
          });
        })
        // 最多顯示10筆資料
        return _self.tempMyMedicalAllNurse.slice(0,10)
      }else{
        return []
      }
    },
    // 合作夥伴id
    partnerid() {
      return this.$store.getters.partner
    },
  },
  data() {
    return {
      authShift: false,
      authAppointment: false,
      addAuthorization: false,
      statusList: {
        shiftStatusList: [],
        queueStatusList: [],
      },
      searchQuery: "",
      myMedicalAllNurse: [],
      tempMyMedicalAllNurse: [],
      delTargetDid: 0
    }
  },
  methods:{
    // 取授權管理清單
    fetchNurseList(){
      var _self = this
      // 儲存授權勾選的狀態
      _self.$store.dispatch('fetchMyNurse',_self.partnerid).then((res)=>{
        var tempArray = res.data.data
        tempArray.forEach(function(v) {
          if(v.status==1)  _self.statusList.shiftStatusList.push(v.nurse_did)
          if(v.status2==1) _self.statusList.queueStatusList.push(v.nurse_did)
        })
      })
    },
    // 新增修改醫事人員
    addAuth(did){
      let payload = {"nurse_did": did}
      updateAuthNurse(payload).then(()=>{
        this.$store.dispatch('fetchMyNurse',this.partnerid)
        this.addAuthorization = false
      })
    },
    // 刪除醫事人員
    delAuth(){
      let payload = {"nurse_did": this.delTargetDid}
      _.pull(this.statusList.shiftStatusList, this.delTargetDid)
      _.pull(this.statusList.queueStatusList, this.delTargetDid)
      delAuthNurse(payload).then(()=>{
        this.$store.dispatch('fetchMyNurse',this.partnerid)
        this.tempMyMedicalAllNurse = _.cloneDeep(this.myMedicalAllNurse)
      })
    },
    // 更新授權類別狀態
    updateAuthor(nurse_did,clickStatusName){
      let payload = {"nurse_did": nurse_did, "statusName": clickStatusName}
      updateAuth(payload).then(()=>{
        this.$store.dispatch('fetchMyNurse',this.partnerid)
      })
    },
    // 開啟授權管理視窗
    showAddAuthorization(){
      this.addAuthorization = true
    },
    // 刪除名單前的警告
    showDeleteWarning(){
      this.$vs.dialog({
        type:'confirm',
        title: this.$t('authorization.delete'),
        text: this.$t('message.confirm_delete_record')+'?',
        accept: this.delAuth,
        acceptText: this.$t('popup.confirm'),
        cancelText: this.$t('popup.cancel'),
      })
    },
    // 取醫師可授權的醫事人員名單
    fetchAuthList(){
      fetchAuthList(this.partnerid).then((res)=>{
        this.myMedicalAllNurse = _.cloneDeep(res.data.data)
        this.tempMyMedicalAllNurse = _.cloneDeep(res.data.data)
      })
    },
  }
}
</script>